import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Connecticut Drowning Accident Attorney",
    description:
      "With Frank Bartlett, Connecticut's drowning accident attorney. he’ll aim to provide justice and compensation for these tragedies, with a focus on child drowning cases.",
    heroH1: "Connecticut Drowning Accident Attorney",
    heroH2: "11 Key Drowning Accident Questions",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>
              CONNECTICUT DROWNING ACCIDENT ATTORNEY|11 KEY DROWNING QUESTIONS
            </h2>
            <p className="mb-8">
              Connecticut’s drowning accident attorney Frank Bartlett’s goal is
              to prevent the loss of children’s lives due to drowning by holding
              negligent parties accountable for these tragic losses. While no
              amount of money can replace a loved one, the legal system has
              provided a way to bring justice to you as a grieving family and to
              provide compensation for your loss.
            </p>
            <p className="mb-8">
              We handle all drowning cases but focus mainly on those that
              involve the loss of a child. We donate a portion of our fees to
              support groups such as Hope After Loss and strongly encourage you
              to contact them or other support groups in your area. You are not
              alone during this difficult time.
            </p>
            <h3>
              Over the years clients have asked many of the same key legal
              questions concerning drowning cases that you may also have.
            </h3>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <h2>
              1) What type of lawyer should I get to handle a drowning case?
            </h2>
            <p className="mb-8">
              A drowning accident due to negligence falls under the part of
              personal injury law called wrongful death. For over a decade
              Attorney Bartlett has focused on wrongful death cases. These are
              complicated cases and require extensive research, investigation
              and experience. For additional information about wrongful death
              cases please visit our wrongful death webpage by clicking here.
            </p>

            <h2>
              2) How long do I have to file a claim, or what is the statute of
              limitations?
            </h2>
            <p className="mb-8">
              This depends on several things that an experienced attorney can
              help you determine. Usually, you have two years from the time of
              the drowning, but this may vary depending on the circumstances of
              the drowning and where the drowning occurred. It is critically
              important to contact an experienced attorney so that valuable,
              time-sensitive information that may affect the outcome of your
              case is not lost.
            </p>

            <h2>
              3) Can I file a drowning lawsuit if I am not the parent of the
              victim?
            </h2>
            <p className="mb-8">
              Typically it is the parent of the child that is the administrator
              of administratrix of the child’s estate that files the suit. We
              can help you determine if you have standing to file a claim and
              what steps have to be taken. Consultations are free and you are
              under no obligation.
            </p>

            <h2>4) What types of drowning cases has your firm seen?</h2>
            <p className="mb-8">
              As a nationally recognized drowning accident law firm, we accept
              referrals and get calls from across the country about drowning
              cases. We have found that negligence is often the cause for most
              drowning cases. We have brought cases where a child needlessly
              died because flotation devices and other lifesaving equipment were
              not available. Other cases stem from improper barriers around
              swimming pools, while others were caused by a failure to properly
              monitor and supervise a swimming facility. We will work with our
              experts to find the root cause of the drowning and to establish
              liability.
            </p>

            <h2>5) How many drowning deaths occur each year?</h2>
            <p className="mb-8">
              Sadly there are over 360,000 drownings a year worldwide with over
              twice as many being males as females. Drowning is the leading
              cause of death of our young children under five years old and the
              second leading cause under age fifteen.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <h2>
              6) Can you help me with a drowning lawsuit if I live in a state
              other than Connecticut?
            </h2>
            <p className="mb-8">
              Yes. In this electronic age distance is no longer an issue. If the
              drowning occurred outside of Connecticut we have an extensive
              network of the top drowning accident attorneys across the country
              that focus on this type of law.
            </p>

            <h2>
              7) Can I file a claim if my loved one didn’t die but was injured?
            </h2>
            <p className="mb-8">
              Yes. We have filed numerous claims resulting from serious
              injuries, such as near drowning, boat accidents, and other water
              related injuries. Don’t feel you may not have a case. Let us help
              you decide. Call us today for a free consultation at 203-439-7717.
            </p>

            <h2>8) How much is my case worth?</h2>
            <p className="mb-8">
              The maximum value of your case can only be calculated after many
              hours and days of work by our staff and network of experts. We can
              give you an estimate based on similar cases that we have handled
              for other clients but every case is different. Our job is to
              maximize your compensation.
            </p>

            <h2>
              9) Won’t the insurance company decide what the drowning case is
              worth?
            </h2>
            <p className="mb-8">
              The insurance company will try to pay you the least amount
              possible. That is their responsibility to their company. We have
              worked as attorneys for insurance companies and know how they try
              to minimize your payment. We now only work to help people injured,
              but the experience gained from working for these companies will
              help ensure that you receive the most possible for your loss.
            </p>

            <h2>10) Should I settle my drowning claim or go to court?</h2>
            <p className="mb-8">
              This can only be determined after months of work on your case by
              an experienced attorney. We prepare every case as though it will
              go to trial with a goal towards providing our clients with the
              best possible settlement option. You will be presented with all
              settlement offers and will have the ultimate authority to settle
              your case or proceed to trial. Because we prepare every case for
              trial, we are ready to try cases that do not settle.
            </p>

            <h2>
              11) Will you be the attorney taking my case to court if it goes to
              trial or will you refer my case to a trial attorney?
            </h2>
            <p className="mb-8">
              We are trial attorneys and we will be taking your drowning case to
              trial if that’s required. Many firms handle your case until trial
              and then have a trial firm such as Bartlett & Grippe bring the
              case to court. An attorney that has worked on your case from the
              beginning is far more familiar with the case details than someone
              that is handed the case before trial.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
